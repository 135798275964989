import React from "react";
import SEO from "../components/common/layout/seo";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";
import { SpacedRepetitionBlogPost } from "../blogPosts/spaced-repetition/SpacedRepetitionBlogPost";
import { whatIsSpacedRepetition } from "../utils/seoText";

export const GettingStartedPage = () => {
  return (
    <Layout>
      <SEO {...whatIsSpacedRepetition} />
      <Navigation />
      <SpacedRepetitionBlogPost />
      <Footer />
    </Layout>
  );
};

export default GettingStartedPage;
