import React from "react";
import { NotionRenderer } from "react-notion-x";
import notionPost from "./scienceBlogPost.json";
import styled from "@emotion/styled";
import { LoomVideoEmbed } from "../../components/common/LoomVideoEmbed";
import { StaticImage } from "gatsby-plugin-image";
import { ScienceTable } from "../../components/common/ScienceTable";
import { StudyingQuoteBlock } from "../../components/common/StudyingQuoteBlock";
import { BigCallToAction } from "../../components/common/call-to-action/BigCallToAction";
const NotionContainerDiv = styled.div`
  & .notion-viewport {
    display: none;
  }
`;

export const SpacedRepetitionBlogPost: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        <div className="max-w-2xl mx-auto">
          <article>
            <header>
              <div className="mb-6 text-center">
                <h2 className="mt-4 text-2xl font-bold md:text-3xl font-heading">
                  Make studying significantly faster through
                  <br />
                  active recall and spaced-repetition
                </h2>
              </div>
            </header>
            <StudyingQuoteBlock />
            <NotionContainerDiv>
              <NotionRenderer recordMap={notionPost as any} />
            </NotionContainerDiv>
            <div className="pr-8 mx-2">
              <ScienceTable />
            </div>
          </article>
        </div>
      </div>
      <div className="mx-0 sm:px-8 sm:mx-auto">
        <BigCallToAction />
      </div>
    </section>
  );
};
