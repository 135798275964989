import React from "react";

export const StudyingQuoteBlock: React.FC = () => {
  return (
    <section className="box-border relative block py-16 overflow-hidden leading-6 text-left bg-no-repeat bg-cover md:py-20 ">
      <div className="w-full px-4 mx-auto leading-6 text-left  max-w-7xl xl:px-24">
        <div className="flex flex-wrap px-12 pt-8 pb-12 -mx-4 border-t border-b border-purple-200 opacity-100 xl:px-0">
          <div className="relative w-full px-4 leading-6 text-left xl:flex-grow-0 xl:flex-shrink-0 lg:flex-grow-0 lg:flex-shrink-0"></div>
          <div className="absolute w-full px-4 mt-2 -ml-10 leading-6 text-left md:flex-grow-0 md:flex-shrink-0">
            <svg
              className="text-purple-200 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              height="24"
            >
              <path d="M9.983 3v7.391C9.983 16.095 6.252 19.961 1 21l-.995-2.151C2.437 17.932 4 15.211 4 13H0V3h9.983zM24 3v7.391c0 5.704-3.748 9.571-9 10.609l-.996-2.151C16.437 17.932 18 15.211 18 13h-3.983V3H24z"></path>
            </svg>
          </div>
          <div className="relative w-full px-4 leading-6 text-left xl:flex-grow-0 xl:flex-shrink-0 lg:flex-grow-0 lg:flex-shrink-0">
            <div className="box-border pt-2 text-lg text-purple-700 md:text-xl">
              The key to surviving in an ever more rapidly changing and complex
              world is learning how to learn.
            </div>
            <div className="box-border flex items-center mt-5 text-purple-700">
              <div className="ml-4 text-sm font-semibold text-left text-gray-700 uppercase">
                ~ The promise and perils of self-regulated study (Kornell and
                Bjork, 2007)
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
